import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PageWrapper from './PageWrapper';

const Etc = () => (
  <PageWrapper forceReadableWidth>
    <Helmet>
      <title>Daha Fazlası</title>
    </Helmet>
    <h1>Daha Fazlası</h1>
    <h2>Teşekkürler</h2>
    <p>
      Burayı ziyaret ettiğin için teşekkürler. Bu websitenin açık kaynaığını burada bulabilirsin: <a href={'#'}>turkerpw/</a>
    </p>
    <h2>İletişim</h2>
    <p>
      <a href="mailto:turker@botscamp.xyz">turker@botscamp.xyz</a>.
    </p>
    <h2>Diğer</h2>
    <ul>
      <li>
        <Link to={'/presence'}>Şu anda ne yapıyorum?</Link>
      </li>
    </ul>
  </PageWrapper>
);

export default Etc;
