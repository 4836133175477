import PageWrapper from './PageWrapper';
import Co from '../components/Co';
import styled from 'styled-components';
import HivenAppIcon from '../assets/images/hiven-app-icon.png';
import GigglAppIcon from '../assets/images/giggl-app-icon.jpg';
import HonkAppIcon from '../assets/images/honk-app-icon.jpg';
import Repo from '../components/Repo';
import { Helmet } from 'react-helmet';

const Where = () => {
  return (
    <PageWrapper>
      <Helmet>
        <title>Yaptıklarım</title>
      </Helmet>
      <h1>Burada neler var?</h1>
      <CoWrapper>
        <Co
          url="https://botscamp.xyz"
          name="BotsCamp"
          iconReference={HivenAppIcon}
          tagline="Discord Bot List"
          role={'Takım / Geliştirici'}
          what={'Discord botlarının bulunduğu en uygun Discord botunu bulmak için geliştirilen bir proje.'}
        />
      </CoWrapper>

      <h3>Açık kaynaklı projelerim</h3>
      <Repo
        name={'Website'}
        url={'https://github.com/turkerpw/old-website'}
        primaryLanguage={'NextJS'}
        description="Kendi websitemin nextjs eski hali."
      />
    </PageWrapper>
  );
};

const CoWrapper = styled.div`
  display: grid;
  width: 100%;
  gap: 2rem 2rem;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export default Where;
