import PageWrapper from './PageWrapper';
import Technology from '../components/Technology';
import {
  ElixirLogo,
  RabbitMQLogo,
  ReactLogo,
  RustLogo,
  SolidityLogo,
  TypescriptLogo,
} from '../components/Icons';
import { Helmet } from 'react-helmet';

const How = () => {
  return (
    <PageWrapper>
      <Helmet>
        <title>Kullandıklarım</title>
      </Helmet>
      <h1>Kullandıklarım!</h1>
      <p>
      Kullandığım bazı şeyler
      </p>

      <Technology
        color="#9c1fa5"
        icon={<ElixirLogo />}
        name="Elixir"
        type="Gerçek zamanlı, Backend"
        useCase={'Birden çok düğümde ölçeklenen hataya dayanıklı gerçek zamanlı sistemler oluşturma'}
      />

      <Technology
        color="#232340"
        icon={<ReactLogo />}
        name="React"
        type="Frontend framework"
        useCase={'Büyük ve etkileşimli web uygulamaları için durum bilgisi olan ve dayanıklı ön uçlar oluşturma'}
      />

      <Technology
        color="#dea584"
        icon={<RustLogo />}
        name="Rust"
        type="Backend, Sistem"
        useCase={
          'Rust NIF\'leri kullanarak Elixir kodunun bölümlerini optimize etme ve verimli sistem kodu yazma'
        }
      />

      <Technology
        color="#007acc"
        icon={<TypescriptLogo />}
        name="TypeScript"
        type="JS Framework"
        useCase={'JS Türleri - projeler genişlediğinde hayatınızı kurtaracak'}
      />
    </PageWrapper>
  );
};

export default How;
