import { Helmet } from 'react-helmet';
import PageWrapper from './PageWrapper';

const Presence = () => (
  <PageWrapper forceReadableWidth>
    <Helmet>
      <title>Şuanda ne yapıyorum?</title>
    </Helmet>
    <h1>👀 Discord Oynuyor</h1>
    <p>
    {' '}
      <a target="_blank" rel="noreferrer" href={'https://github.com/phineas/lanyard'}>
        Lanyard
      </a>{' '}
      tarafından yapıldı.
    </p>
  </PageWrapper>
);

export default Presence;
