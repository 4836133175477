import { useMemo } from 'react';
import { Tooltip } from 'react-tippy';
import PageWrapper from './PageWrapper';

const BIRTH = new Date('2008-12-28T10:15:00Z');
const YEAR_MILLIS = 31556952000;

const Home = () => {
  const age = useMemo(() => Math.floor((Date.now() - BIRTH.getTime()) / YEAR_MILLIS), []);

  return (
    <PageWrapper forceReadableWidth>
      <h1>Hoş geldin!</h1>
      <p>Websiteme hoş geldin!</p>
      <p>
        Yandaki butonlardan biraz bilgi alabilirsin!
      </p>
    </PageWrapper>
  );
};

export default Home;
